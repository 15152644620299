import React, { useState } from 'react';
import prductDetails from './css/productDetails.module.css';
import { PiRectangleFill } from "react-icons/pi";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { AiOutlineLike } from "react-icons/ai";
import user from './images/verify2.png';
import { FaPlus } from "react-icons/fa6";
import { MdAddShoppingCart } from "react-icons/md";
const ProductModal = ({ isOpen, onClose, name, price , images }) => {
  const [quantity, setQuantity] = useState(1);
  const [selectedImage, setSelectedImage] = useState(images[0]);
  const handleIncrease = () => setQuantity(quantity + 1);
  const handleDecrease = () => setQuantity(quantity > 1 ? quantity - 1 : 1);

  if (!isOpen) return null; // Don't render the modal if it’s closed
  const imagees = [
    "https://images.pexels.com/photos/7679796/pexels-photo-7679796.jpeg?auto=compress&cs=tinysrgb&w=1440&h=270&dpr=2",
    "https://images.pexels.com/photos/6690884/pexels-photo-6690884.jpeg?auto=compress&cs=tinysrgb&w=1440&h=270&dpr=2",
    "https://images.pexels.com/photos/3243090/pexels-photo-3243090.jpeg?auto=compress&cs=tinysrgb&w=1440&h=270&dpr=2",
    "https://images.pexels.com/photos/236915/pexels-photo-236915.jpeg?auto=compress&cs=tinysrgb&w=1440&h=270&dpr=2",
    "https://images.pexels.com/photos/3568521/pexels-photo-3568521.jpeg?auto=compress&cs=tinysrgb&w=1440&h=270&dpr=2"
  ];

  return (
    <div className={prductDetails.modalOverlay} onClick={onClose}>
      <div className={prductDetails.productModal} onClick={(e) => e.stopPropagation()}>
        <button className={prductDetails.closeButton} onClick={onClose}>X</button>
        
        <div className={prductDetails.modalHeader}>
        <div className={prductDetails.tag2}>
            <HiOutlineDotsVertical className={prductDetails.dots}/>
            <AiOutlineLike className={prductDetails.global}/>
             </div>
<div className={prductDetails.user}>
    <img src={user} alt=''/>
    <span className={prductDetails.userName}>Seba Atallah</span>
</div>
          
        </div>
        
        <div className={prductDetails.modalBody}>
        <div className={prductDetails.content}>
            <div className={prductDetails.row}>
          <span className={prductDetails.badgeNew}>جديد</span>
                <div className={prductDetails.iconRow}>
                    <PiRectangleFill className={prductDetails.location} />
                    <p className={prductDetails.location}>
                        الجزائر - عناية
                    </p>
                </div>
            </div>
          
          <p className={prductDetails.description}>
            Description A Written Explanation That Outlines The Essential Responsibilities And Requirements For A Vacant Position.
          </p>
          
          <div className={prductDetails.colorOptions}>
            <span className={prductDetails.label}>الألوان</span>
            <div className={prductDetails.colors}>
              <span className={`${prductDetails.color} ${prductDetails.blue}`}></span>
              <span className={`${prductDetails.color} ${prductDetails.yellow}`}></span>
              <span className={`${prductDetails.color} ${prductDetails.red}`}></span>
            </div>
          </div>

          <div className={prductDetails.quantityControl}>
            <span className={prductDetails.label}>الكمية</span>
            <button onClick={handleDecrease} className={prductDetails.btn}>-</button>
            <span className={prductDetails.quantity}>{quantity}</span>
            <button onClick={handleIncrease}className={prductDetails.btn}>+</button>
          </div>

          <div className={prductDetails.costInfo}>
            <p>تكلفة الشحن، الخصم و الضريبة</p>
            <FaPlus />
          </div>

          <div className={prductDetails.priceAndCart}>
            <button className={prductDetails.addToCart2}>إضافة للسلة</button>
            <button className={prductDetails.addToCart}> 
                <p>(0)السلة</p>
                <MdAddShoppingCart />
                </button>
          </div>
            </div>
            <div>
            <div className={prductDetails.container}>
      {/* Main Featured Image */}
      <div className={prductDetails.featuredImageContainer}>
        <img src={selectedImage} alt={name} className={prductDetails.featuredImage} />
      </div>

      {/* Thumbnail Images */}
      <div className={prductDetails.thumbnailContainer}>
        {imagees.slice(0, 4).map((image, index) => ( // Limit to four thumbnails
          <img
            key={index}
            src={image}
            alt={`${name} ${index + 1}`}
            className={`${prductDetails.thumbnailImage} ${selectedImage === image ? prductDetails.activeThumbnail : ''}`}
            onClick={() => setSelectedImage(image)}
          />
        ))}
      </div>
    </div>

      {/* Display thumbnail images */}
      {/* <div className={prductDetails.thumbnailContainer}>
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`${name} ${index + 1}`}
            className={`${prductDetails.thumbnailImage} ${selectedImage === image ? prductDetails.activeThumbnail : ''}`}
            onClick={() => setSelectedImage(image)}
          />
        ))}
      </div> */}
             
             <div className={prductDetails.productInfo} >
             <div className={prductDetails.productName}>{name}</div>
                <div className={prductDetails.price}>
                    <sup>
                        <span className={prductDetails.dolar}>$</span>
                    </sup>

                    <span className={prductDetails.priceNum}>{price}</span>
                </div>
             </div>
               
  
            </div>
       

        </div>
      </div>
    </div>
  );
};

export default ProductModal;
