import React from 'react';
import SearchBar from '../components/SearchBar';
import Landing from "../components/Landing";
import Categories from '../components/Categories';
import DailyProduct from '../components/DailyProduct';
import Competitions from '../components/Competitions';
import Recommended from '../components/Recommended';
import VerifiedAccounts from '../components/VerifiedAccounts';
import SpecialOffers from '../components/SpecialOffers';
import SpecialServices from '../components/SpecialServices';
import SpecialJobs from '../components/SpecialJobs';


function Home() {
  return (
    <div>
      <SearchBar/>
     <Landing/>
     <Categories/>
     <DailyProduct/>
     <Competitions/>
     <Recommended/>
     <SpecialServices/>
     <SpecialJobs/>
     <SpecialOffers/>
     <VerifiedAccounts/>
    </div>
  )
}

export default Home
