import React from "react";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom"; 

const images = [
  "https://images.pexels.com/photos/125779/pexels-photo-125779.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  "https://images.pexels.com/photos/190819/pexels-photo-190819.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  "https://images.pexels.com/photos/125779/pexels-photo-125779.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  "https://images.pexels.com/photos/190819/pexels-photo-190819.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  "https://images.pexels.com/photos/125779/pexels-photo-125779.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
];

const ImageCarousel = () => {
  const navigate = useNavigate(); 

  const handleImageClick = () => {
    navigate("/profile");
  };

  const settings = {
    dots: false,          
    infinite: true,        
    speed: 500,            
    slidesToShow: 1,      
    slidesToScroll: 1,    
    autoplay: true,        
    autoplaySpeed: 3000,   
    arrows: false,         
  };

  return (
    <div style={{ width: "100%", margin: "0 auto", overflow: "hidden" }}>
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} onClick={handleImageClick}>  {/* Click event to navigate */}
            <img
              src={image}
              alt={`Slide ${index + 1}`}
              style={{ width: "100%", height: "100%", cursor: "pointer" }} // Add cursor for click feedback
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImageCarousel;
