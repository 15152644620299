import React, { useState } from 'react';
import dailyProductStyles from './css/dailyProduct.module.css'
import ProductList from './ProductCard';


function DailyProduct() {
 

  return (
    <div className={dailyProductStyles.container}>
        <div className={dailyProductStyles.headerContainer}>
            <h2 className={dailyProductStyles.title}> منتجات يومية</h2>
            <p className={dailyProductStyles.seeMore}>عرض الكل</p>
        </div>
      <ProductList/>
    </div>
  );
}



export default DailyProduct;
