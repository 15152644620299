import React from 'react';
import styles from './SearchBar.module.css';

const SearchBar = () => {
  return (
    <div className={styles.searchContainer}>
      <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/ecf3455bb641b2fb1acd8d5b4490e2530951a76c8045e6964584381c4046c74c?placeholderIfAbsent=true&apiKey=52ee78b612ab4e92b3964be63b294cdb" className={styles.searchIcon} alt="" />
      <div className={styles.searchPlaceholder}>ابحث عن ماذا تريد ؟</div>
    </div>
  );
};

export default SearchBar;