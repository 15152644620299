import React from 'react'
import CompetitionStyles from './css/competitions.module.css';
import CompetitionsList from './CompetitionsCard';
function Competitions() {
  return (
    <div className={CompetitionStyles.container}>
        <div className={CompetitionStyles.headerContainer}>
            <h2 className={CompetitionStyles.title}> المسابقات</h2>
            <p className={CompetitionStyles.seeMore}>عرض الكل</p>
        </div>
        <CompetitionsList/>
    </div>
  )
}

export default Competitions
