import React, { useState, useEffect } from "react";
import { db } from "../../firebase/firebase";
import { collection, getDocs } from "firebase/firestore";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./ServiceForm.module.css";
const ServiceForm = () => {
  const [categories, setCategories] = useState([]); // Store fetched categories
  const [selectedTags, setSelectedTags] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [video, setVideo] = useState(null);

  // Fetch categories from Firestore
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "categories1"));
        const categoriesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCategories(categoriesData[1].subCategories); // Adjust this if you need specific data structure
        console.log("Fetched categories:", categoriesData[1].subCategories);
      } catch (error) {
        console.error("Error fetching categories:", error);
        toast.error("خطأ في جلب الفئات");
      }
    };

    fetchCategories();
  }, []);

  // Validation schema
  const validationSchema = Yup.object({
    serviceName: Yup.string().required("اسم العرض مطلوب"),
    status: Yup.string().required("الحالة مطلوبة"),
    categories: Yup.array().min(1, "اختر فئة واحدة على الأقل"),
    description: Yup.string().required("الوصف مطلوب"),
  });

  // Formik setup
  const formik = useFormik({
    initialValues: {
      serviceName: "",
      status: "",
      categories: [],
      description: "",
    },
    validationSchema,
    onSubmit: (values) => {
      const formData = {
        ...values,
        images,
        video,
      };
      console.log("Submitting form data:", formData);
    },
  });

  const addTag = (tag) => {
    // Only add tag if it's not already selected
    if (!selectedTags.includes(tag)) {
      const newTags = [...selectedTags, tag];
      setSelectedTags(newTags);
      formik.setFieldValue("categories", newTags); // Update Formik's field
    }
    setIsDropdownOpen(false); // Close dropdown after selection
  };

  // Remove tag function
  const removeTag = (tagToRemove) => {
    const updatedTags = selectedTags.filter((tag) => tag !== tagToRemove);
    setSelectedTags(updatedTags);
    formik.setFieldValue("categories", updatedTags); // Update Formik's field
  };

  const handleButtonClick = () => {
    toast.warning("برجاء تحميل التطبيق", {
      position: "top-left",
      autoClose: 3000,
      //   hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: "custom-toast", // Custom class for styling
    });
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    if (files.length + images.length <= 5) {
      // Limit to 5 images
      setImages([...images, ...files]);
    } else {
      alert("يمكنك إضافة حتى 5 صور فقط");
    }
  };

  const handleVideoUpload = (e) => {
    const file = e.target.files[0];
    setVideo(file);
  };

  const handleDivClick = () => {
    document.getElementById("imgs").click(); // Trigger file input click
  };
  const handleVideoClick = () => {
    document.getElementById("video").click(); // Trigger file input click
  };
  return (
    <section className={styles.formSection}>
      <form className={styles.formContent} onSubmit={formik.handleSubmit}>
        <header className={styles.formHeader}> </header>

        <label htmlFor="images" className={styles.formLabel}>
          <span className={styles.requiredField}>*</span> صور
        </label>
        <div className={styles.imageUpload}>
          <div
            onClick={handleDivClick}
            className={styles.uploadContent}
            role="button"
            tabIndex={0} // Makes the div focusable
          >
            <img
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/7884097dbe4a1fcc2be7a1a548890ead6b456523ae4c1250d9c414bedec877f9?placeholderIfAbsent=true&apiKey=52ee78b612ab4e92b3964be63b294cdb"
              alt="Upload Icon"
              className={styles.uploadIcon}
            />
            <p className={styles.uploadInstructions}>
              <span style={{ color: "#303030" }}>اختر يدويًا</span> أو اسحب
              الملفات هنا
            </p>
            <p className={styles.uploadLimit}>اضم صور العرض بحد اقصي 5 صور </p>
          </div>

          {/* Hidden file input */}
          <input
            id="imgs"
            type="file"
            multiple
            onChange={handleImageUpload}
            accept="image/*"
            style={{ display: "none" }} // Hide the file input
          />
        </div>
        {images.length > 0 && (
          <div className={styles.imageUpload}>
            {images.map((img, index) => (
              <img
                key={index}
                width={150}
                height={150}
                src={URL.createObjectURL(img)}
                alt="Preview"
                className={styles.previewImage}
              />
            ))}
          </div>
        )}
        <label htmlFor="serviceName" className={styles.formLabel}>
          <span className={styles.requiredField}>*</span> اسم الخدمة
        </label>
        <input
          id="serviceName"
          name="serviceName"
          type="text"
          className={styles.formInput}
          placeholder="اسم الخدمة"
          onChange={formik.handleChange}
          value={formik.values.serviceName}
        />
        {formik.errors.serviceName && formik.touched.serviceName && (
          <div className={styles.error}>{formik.errors.serviceName}</div>
        )}

        <label htmlFor="status" className={styles.formLabel}>
          <span className={styles.requiredField}>*</span> الحالة
        </label>
        <select
          id="status"
          name="status"
          className={styles.formSelect}
          onChange={formik.handleChange}
          value={formik.values.status}
        >
          <option value="">اختر الحالة</option>
          <option value="جديد">اعلان</option>
          <option value="مستعمل">خدمة</option>
        </select>

        {formik.errors.status && formik.touched.status && (
          <div className={styles.error}>{formik.errors.status}</div>
        )}

        <label htmlFor="categories" className={styles.formLabel}>
          <span className={styles.requiredField}>*</span> الفئات
        </label>
        <div className={styles.dropdown}>
          <button
            type="button"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className={styles.formSelect}
          >
            {isDropdownOpen ? "إغلاق" : "أختر الفئات"}
          </button>
          {isDropdownOpen && (
            <div className={styles.dropdownContent}>
              {categories.map((category) => (
                <button
                  key={category.id}
                  onClick={() => addTag(category.arabicName)}
                  className="btn btn-outline-primary w-100 mb-2"
                >
                  {category.arabicName}
                </button>
              ))}
            </div>
          )}
          <div className={styles.items}>
            {selectedTags.map((tag, index) => (
              <div key={index} className={styles.item}>
                {tag}
                <span
                  className={styles.tags}
                  onClick={() => removeTag(tag)}
                  style={{ cursor: "pointer" }}
                >
                  <i className="fa-solid fa-trash" style={{ color: "red" }}></i>
                </span>
              </div>
            ))}
          </div>
        </div>
        {formik.errors.categories && formik.touched.categories && (
          <div className={styles.error}>{formik.errors.categories}</div>
        )}

        <label htmlFor="description" className={styles.formLabel}>
          <span className={styles.requiredField}>*</span> الوصف
        </label>
        <textarea
          id="description"
          name="description"
          className={styles.formTextarea}
          placeholder="وصف قصير"
          onChange={formik.handleChange}
          value={formik.values.description}
        />
        {formik.errors.description && formik.touched.description && (
          <div className={styles.error}>{formik.errors.description}</div>
        )}

        <label htmlFor="video" className={styles.formLabel}>
          <span className={styles.requiredField}>*</span> فيديو
        </label>

        <input
          style={{ display: "none" }} // Hide the file input
          id="video"
          type="file"
          onChange={handleVideoUpload}
          accept="video/*"
        />
        <div className={styles.imageUpload}>
          <div
            onClick={handleVideoClick}
            className={styles.uploadContent}
            role="button"
            tabIndex={0}
          >
            <img
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/7884097dbe4a1fcc2be7a1a548890ead6b456523ae4c1250d9c414bedec877f9?placeholderIfAbsent=true&apiKey=52ee78b612ab4e92b3964be63b294cdb"
              alt="Upload Icon"
              className={styles.uploadIcon}
            />
            <p className={styles.uploadInstructions}>
              <span style={{ color: "#303030" }}>اختر يدويًا</span> أو اسحب
              الملفات هنا
            </p>
            <p className={styles.uploadLimit}>ارفع فديو هنا</p>
            {video && <p>{video.name}</p>}
          </div>
        </div>

        <div>
          {/* Yellow "إضافة" buttons with labels */}
          <div>
            <div className={styles.buttonSection}>
              <button
                onClick={handleButtonClick}
                className={styles.premiumButton}
              >
                أضف مع الأجر $ 9.99 +
              </button>
              <span className={styles.label}>خدمات مميزة</span>
            </div>
            <div className={styles.buttonSection}>
              <button
                onClick={handleButtonClick}
                className={styles.premiumButton}
              >
                محلي
              </button>
              <span className={styles.label}> </span>
            </div>

            <div className={styles.buttonSection}>
              <button
                onClick={handleButtonClick}
                className={styles.premiumButton}
              >
                اختار عدة دول $ 6.99 +
              </button>
              <span className={styles.label}> بلدان</span>
            </div>

            <div className={styles.buttonSection}>
              <button
                onClick={handleButtonClick}
                className={styles.premiumButton}
              >
                {" "}
                دولي $ 10.99 +
              </button>
              <span className={styles.label}> </span>
            </div>

            <div className={styles.buttonSection}>
              <button
                onClick={handleButtonClick}
                className={styles.premiumButton}
              >
                {" "}
                التواصل مع الحساب{" "}
              </button>
              <span className={styles.label}>طريقة الدفع </span>
            </div>
          </div>
        </div>
        <button
          type="submit"
          className="btn btn-lg w-100 mt-5"
          style={{
            backgroundColor: "#FFDE59",
            color: "fff",
            fontWeight: "400",
          }}
          onClick={handleButtonClick}
        >
          اضف خدمة
        </button>
      </form>
      <ToastContainer />
    </section>
  );
};

export default ServiceForm;
