import * as React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import RecommendedCardStyles from './css/recommendedCard.module.css';
import { BiSolidLeftArrow } from "react-icons/bi";
import { BiSolidRightArrow } from "react-icons/bi";
import { PiGlobeHemisphereWestThin } from "react-icons/pi";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { PiRectangleFill } from "react-icons/pi";
const products = [
  { name: "Software Engineer", price: '6.99', status: 'فرصة عمل' , image:"https://images.pexels.com/photos/327540/pexels-photo-327540.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" },
  { name: "Operations Manager", price: '12.99', status: 'طلب وظيفة' , image:"https://images.pexels.com/photos/392018/pexels-photo-392018.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"  },
  { name: "Mobile App Developer", price: '8.99', status: 'فرصة عمل' , image:"https://images.pexels.com/photos/327540/pexels-photo-327540.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"  },
  { name: "Accountant", price: '9.99', status: 'طلب وظيفة' , image:"https://images.pexels.com/photos/392018/pexels-photo-392018.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"  },
  { name: "Cybersecurity Analyst", price: '7.99', status: 'فرصة عمل' , image:"https://images.pexels.com/photos/327540/pexels-photo-327540.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"  },
  { name: "UI/UX Designer", price: '14.99', status: 'طلب وظيفة', image:"https://images.pexels.com/photos/392018/pexels-photo-392018.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"  },
  { name: "Web Developer", price: '11.99', status: 'فرصة عمل', image:"https://images.pexels.com/photos/327540/pexels-photo-327540.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"  },
  { name: "Sales Executive", price: '10.99', status: 'طلب وظيفة', image:"https://images.pexels.com/photos/392018/pexels-photo-392018.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"  },
  { name: "Content Writer", price: '6.49', status: 'فرصة عمل', image:"https://images.pexels.com/photos/327540/pexels-photo-327540.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"  },
  { name: "HR Manager", price: '15.99', status: 'طلب وظيفة' , image:"https://images.pexels.com/photos/392018/pexels-photo-392018.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" },
  { name: "Project Manager", price: '16.99', status: 'فرصة عمل', image:"https://images.pexels.com/photos/327540/pexels-photo-327540.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"  },
  { name: "Marketing Specialist", price: '13.99', status: 'طلب وظيفة', image:"https://images.pexels.com/photos/392018/pexels-photo-392018.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"  },
  { name: "Data Scientist", price: '9.99', status: 'طلب وظيفة', image:"https://images.pexels.com/photos/327540/pexels-photo-327540.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"  },
  { name: "Software Engineer", price: '10.99', status: 'فرصة عمل', image:"https://images.pexels.com/photos/327540/pexels-photo-327540.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"  },
  { name: "Operations Manager", price: '6.49', status: 'فرصة عمل' , image:"https://images.pexels.com/photos/327540/pexels-photo-327540.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" },
  { name: "Mobile App Developer", price: '12.99', status: 'طلب وظيفة', image:"https://images.pexels.com/photos/392018/pexels-photo-392018.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"  },
];

function SpecialJobsCard({ name, price, status , image }) {
    return (
        <div className={RecommendedCardStyles.card}>
        <div className={RecommendedCardStyles.imagePlaceholder}>
          <img loading="lazy" src={image} alt={name} className={RecommendedCardStyles.imagePlaceholder} />
          {/* <div className={RecommendedCardStyles.indicatorDots}>
            <span className={RecommendedCardStyles.dot}></span>
            <span className={RecommendedCardStyles.dot}></span>
            <span className={RecommendedCardStyles.dot}></span>
          </div> */}
        </div>
        
        <div className={RecommendedCardStyles.row}>
        <div className={RecommendedCardStyles.tag}>{status}</div>
        <div className={RecommendedCardStyles.tag2}>
            <HiOutlineDotsVertical className={RecommendedCardStyles.dots}/>
            <PiGlobeHemisphereWestThin className={RecommendedCardStyles.global}/>
             </div>
        </div>

        <div className={RecommendedCardStyles.productName}>{name}</div>
        
            <div className={RecommendedCardStyles.row}>
                <div className={RecommendedCardStyles.iconRow}>
                    <PiRectangleFill className={RecommendedCardStyles.location} />
                    <p className={RecommendedCardStyles.location}>
                        الجزائر - عناية
                    </p>
                </div>


                <div className={RecommendedCardStyles.price}>
                    <sup>
                        <span className={RecommendedCardStyles.dolar}>$</span>
                    </sup>

                    <span className={RecommendedCardStyles.priceNum}>{price}</span>
                </div>
            </div>

        </div>
      
    );
  }

function SpecialJobsList() {
    const sliderRef = React.useRef(null);
    const [currentIndex, setCurrentIndex] = React.useState(0);
  
    const sliderSettings = {
      dots: false,
      infinite: false,           
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 2000,
      cssEase: 'linear',
      beforeChange: (oldIndex, newIndex) => setCurrentIndex(newIndex),
    };
  
    const handlePrevious = () => {
      if (currentIndex > 0) {
        sliderRef.current.slickPrev();
      }
    };
  
    const handleNext = () => {
      if (currentIndex < products.length - 4) {
        sliderRef.current.slickNext();
      }
    };
  
    const isNextDisabled = currentIndex >= products.length - 4;
    const isPrevDisabled = currentIndex === 0;
  
    return (
      <div style={{ maxWidth: '80%', margin: '0 auto' }}>
        <Slider ref={sliderRef} {...sliderSettings}>
          {products.map((product, index) => (
            <div key={index} style={{ padding: '10px' }}>
              <SpecialJobsCard name={product.name} price={product.price} status={product.status} image={product.image} />
            </div>
          ))}
        </Slider>

      <div className={RecommendedCardStyles.btnContainer}>
        <button
      onClick={handlePrevious} 
      disabled={isPrevDisabled}
      className={isPrevDisabled ? RecommendedCardStyles.disabledButton : RecommendedCardStyles.activeButton}
        >
        <BiSolidLeftArrow />
        </button>
        <button 
         onClick={handleNext} 
         disabled={isNextDisabled}
         className={isNextDisabled ? RecommendedCardStyles.disabledButton : RecommendedCardStyles.activeButton}
        >
        <BiSolidRightArrow />
        </button>
        
      </div>
    </div>
  );
}


export default SpecialJobsList;
