import React from "react";
import MainComponent from "../components/forms/MainComponent";
import styles from "../components/forms/JobForm.module.css";
import JobForm from "../components/forms/JobForm";

const AddJob = () => {
  return (
    <>
      <MainComponent pageTitle="إضافة وظيفة" /> 
      <div className={styles.container}>
        <div className={styles.formLayout}>
          <JobForm />
        </div>
      </div>
    </>
  );
};

export default AddJob;
