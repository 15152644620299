import React from 'react';
import { signInWithPopup, signInWithEmailAndPassword } from 'firebase/auth';
import { auth, googleProvider, facebookProvider } from '../../firebase/firebase';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import styles from './login.module.css';

const Login = () => {
    const navigate = useNavigate();

    const loginValidationSchema = Yup.object().shape({
        email: Yup.string()
            .email('عنوان البريد الإلكتروني غير صحيح')
            .required('البريد الإلكتروني مطلوب'),
        password: Yup.string()
            .min(8, 'يجب أن تكون كلمة المرور على الأقل 8 أحرف')
            .required('كلمة المرور مطلوبة'),
    });

    const handleEmailPasswordSignIn = (values) => {
      const { email, password } = values;
  
      signInWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
              const user = userCredential.user; // Get the user data from the response
  
              // Log the response
              console.log('User Response:', user.reloadUserInfo);
  
              // Save login status and email to localStorage
              localStorage.setItem('isLoggedIn', 'true');
              localStorage.setItem('user',user.reloadUserInfo);
  
              // Display success message in Arabic
              toast.success("تم تسجيل الدخول بنجاح!");
  
              // Send the response data to an endpoint (optional)
   
              // Redirect to the home page after successful login
              navigate('/');
              window.location.reload();
            })
          .catch(error => {
              console.error('Error during Email/Password Sign In:', error);
  
              // Specific handling for errors with Arabic translations
              if (error.code === 'auth/user-not-found') {
                  toast.error('لم يتم العثور على حساب بهذا البريد الإلكتروني. يرجى التحقق أو إنشاء حساب جديد.');
              } else if (error.code === 'auth/wrong-password') {
                  toast.error('كلمة المرور غير صحيحة. يرجى المحاولة مرة أخرى.');
              } else {
                  toast.error(error.message || 'حدث خطأ أثناء تسجيل الدخول.');
              }
          });
  };
  
    
    const GoTOSignUP = ()=>{
      navigate('/signup');
  }
    // Google Sign-In Handler
    // const handleGoogleSignIn = () => {
    //     signInWithPopup(auth, googleProvider)
    //         .then((result) => {
    //             const user = result.user;
    //             if (result.additionalUserInfo.isNewUser) {
    //                 toast.success("Welcome! Please complete your sign-up.");
    //                 navigate('/signup');
    //             } else {
    //                 toast.success("Welcome back!");
    //                 navigate('/home');
    //             }
    //         })
    //         .catch(error => {
    //             console.error('Error during Google Sign In:', error);
    //             toast.error(error.message || 'An error occurred during Google sign-in.');
    //         });
    // };

    // Facebook Sign-In Handler
    const handleFacebookSignIn = () => {
        signInWithPopup(auth, facebookProvider)
            .then(() => navigate('/'))
            .catch(error => {
                console.error('Error during Facebook Sign In:', error);
                toast.error(error.message || 'An error occurred during Facebook sign-in.');
            });
    };
 
    const handleGoogleSignIn = () => {
 
    signInWithPopup(auth, googleProvider)
      .then((result) => {
        const user = result.user;
        if (result.additionalUserInfo.isNewUser) {
            toast.success("Welcome! Please complete your sign-up.");
            navigate('/signup');
        } else {
            toast.success("Welcome back!");
            navigate('/home');
        }
      }).catch((error) => {
        console.error('Error during Google Sign In:', error);
        
        switch (error.code) {
            case 'auth/account-exists-with-different-credential':
                toast.error('An account already exists with the same email but different sign-in credentials.');
                break;
            case 'auth/auth-domain-config-required':
                toast.error('Authentication domain configuration required.');
                break;
            case 'auth/cancelled-popup-request':
                toast.info('Sign-in process cancelled. Please try again.');
                break;
            case 'auth/operation-not-allowed':
                toast.error('Operation not allowed.');
                break;
            case 'auth/popup-blocked':
                toast.error('Popup was blocked by the browser. Please allow popups for this site.');
                break;
            case 'auth/popup-closed-by-user':
                toast.info('Popup was closed before sign-in could complete.');
                break;
            default:
                toast.error('An unknown error occurred. Please try again.');
                break;
        }
      });
};

  return (
    <main className={styles.loginContainer}>
                <ToastContainer />

                <Formik
  initialValues={{ email: '', password: '' }}
  validationSchema={loginValidationSchema}
  onSubmit={(values, { setSubmitting }) => {
    handleEmailPasswordSignIn(values);  // Call the sign-in function with values from Formik
    setSubmitting(false);  // Reset the submitting state
  }}
>
  {({ isSubmitting, setFieldValue, values }) => (
    <Form className={styles.loginContainer}>
      <h1 className={styles.loginTitle}>تسجيل الدخول</h1>

      <div className={styles.inputContainer}>
        <label htmlFor="email" className={styles.inputLabel}>البريد الإلكتروني</label>
        <Field type="email" name="email" className={styles.inputField} placeholder="البريد الإلكتروني" />
        <ErrorMessage name="email" component="div" className={styles.error} />
      </div>

      <div className={styles.inputContainer}>
        <label htmlFor="password" className={styles.inputLabel}>كلمة المرور</label>
        <div className={styles.passwordContainer}>
          <FontAwesomeIcon
            icon={values.showPassword ? faEye : faEyeSlash}
            onClick={() => setFieldValue('showPassword', !values.showPassword)}
            className={styles.passwordIcon}
            style={{ cursor: 'pointer' }}
            aria-label={values.showPassword ? "Hide password" : "Show password"}
          />

          <Field
            type={values.showPassword ? "text" : "password"}
            name="password"
            placeholder="********"
            aria-label="كلمة المرور"
            className={styles.inputField}
          />
        </div>

        <ErrorMessage name="password" component="div" className={styles.error} />
      </div>

      <button type="submit" className={styles.loginButton} disabled={isSubmitting}>
        تسجيل الدخول
      </button>
    </Form>
  )}
</Formik>

{/* 
      <div className={styles.dividerContainer}>
        <div className={styles.divider} />
        <span className={styles.dividerText}>أو سجل الدخول بإستخدام</span>
        <div className={styles.divider} />
      </div>
      <div className={styles.socialLoginContainer}>
        <img onClick={handleGoogleSignIn}
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/7479a32179321614532757874f1b0f838bf7fa2927de6ecb7c2e107f0d5cb41d?placeholderIfAbsent=true&apiKey=52ee78b612ab4e92b3964be63b294cdb"
          alt="Social Login Option 1"
          className={styles.socialIcon}
        />
        <img onClick={handleFacebookSignIn}
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/7e6aa850f90a380fb85be84179a1ab90d2c146dcc6b9f27a87a6e2bf5b6a6160?placeholderIfAbsent=true&apiKey=52ee78b612ab4e92b3964be63b294cdb"
          alt="Social Login Option 2"
          className={styles.socialIcon}
        />
      </div> */}
      <p className={styles.signupPrompt} >
        لا تملك حساب ؟ <a onClick={GoTOSignUP}  className={styles.signupLink}>حساب جديد</a>
      </p>
    </main>
  );
};

export default Login;
