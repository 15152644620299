import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import competitionCardStyle from './css/competitionsCard.module.css';
import image from './images/sliderDots.svg';

function CompetitionsCard({ image, title, participants, points }) {
    return (
        <div className={competitionCardStyle.card}>
            {/* Image Container */}
            <div className={competitionCardStyle.imageContainer}>
                <img src={image} alt={title} className={competitionCardStyle.image} />
                <div className={competitionCardStyle.imageOverlay}>{title}</div>
            </div> {/* Participants and Points */}
            {participants && points && (<div className={competitionCardStyle.details}>
                <div>عدد المشتركين: {participants}</div>
                <div>النقاط الابتدائية: {points}</div>
                 </div>)}
            {/* Button */}
            <button className={competitionCardStyle.button}>اشترك</button> 
            </div>
            );
}

function CompetitionsList() {

    const contests = [
        { image:require('./images/img1.png'), title: 'مسابقة اربح نقاط' },
        { image:require('./images/img2.png'), title: 'مسابقة زد نقاطك' },
        { image:require('./images/img3.png'), title: 'مسابقة اكسب هدية بنقاط' },
      ];
     
    
      const settings = { 
        dots: false , 
        infinite: true,
         speed: 500, 
         slidesToShow: 3,
          slidesToScroll: 1,
           arrows: false, 
           
         };

  
   
  
    return (
        <div className={competitionCardStyle.sliderContainer}>
             <Slider {...settings}> {contests.map((contest, index) => ( <CompetitionsCard key={index} image={contest.image} title={contest.title} participants={contest.participants} points={contest.points} /> ))}
              </Slider> 
              <div className={competitionCardStyle.dots}>
                <img src={image}  className={competitionCardStyle.images} />
              </div>
              </div>
  );
}



export default CompetitionsList;