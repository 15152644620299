import React from 'react';
import styles from './MainComponent.module.css';
import SearchBar from './SearchBar';
import NavItem from './NavItem';
import Header from './Header';

const MainComponent = ({ pageTitle }) => {
  const navItems = [];

  return (
    <main className={styles.mainContainer}>
      <div className={styles.contentWrapper}>
        <nav className={styles.navContainer}>
          {navItems.map((item, index) => (
            <NavItem key={index}>{item}</NavItem>
          ))}
          <SearchBar />
        </nav>
        <Header />
      </div>
      <div className={styles.breadcrumb}>
        الرئيسية / <span className={styles.boldText}>{pageTitle}</span>
      </div>
    </main>
  );
};

export default MainComponent;
