import React from 'react';
import styles from './Header.module.css';

const Header = () => {
  return (
    <header className={styles.header}>
      <div>الشعار</div>
      <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/47ad90cd802583edc0c64acd3bf6501c888df6990724f530aa8b641d7016d4b3?placeholderIfAbsent=true&apiKey=52ee78b612ab4e92b3964be63b294cdb" className={styles.logo} alt="" />
    </header>
  );
};

export default Header;