import React from "react";
import MainComponent from "../components/forms/MainComponent";
import styles from "../components/forms/AuctionForm.module.css";
import AuctionForm from "../components/forms/AuctionForm";

const AddAuction = () => {
  return (
    <>
      <MainComponent pageTitle="إضافة مزاد" /> 
      <div className={styles.container}>
        <div className={styles.formLayout}>
          <AuctionForm />
        </div>
      </div>
    </>
  );
};

export default AddAuction;
