import React from "react";
import MainComponent from "../components/forms/MainComponent";
import styles from "../components/forms/ProductForm.module.css";
import ProductForm from "../components/forms/ProductForm";

const AddProduct = () => {
  return (
    <>
      <MainComponent pageTitle="إضافة منتج " /> 
      <div className={styles.container}>
        <div className={styles.formLayout}>
          <ProductForm />
        </div>
      </div>
    </>
  );
};

export default AddProduct;
