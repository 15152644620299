import React from 'react';
import styles from './css/UserProfile.module.css';

const StatItem = ({ value, label }) => (
  <div className={styles.statItem}>
    <span className={styles.statValue}>{value}</span>
    <span className={styles.statLabel}>{label}</span>
  </div>
);

export default StatItem;