import * as React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import RecommendedCardStyles from './css/recommendedCard.module.css';
import { BiSolidLeftArrow } from "react-icons/bi";
import { BiSolidRightArrow } from "react-icons/bi";
import { PiGlobeHemisphereWestThin } from "react-icons/pi";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { PiRectangleFill } from "react-icons/pi";
const services = [
  {
    name: "Website Development",
    description: "Custom website design and development tailored to your needs.",
    price: "$500",
    status: "خدمة",
    category: "Technology",
    image: "https://images.pexels.com/photos/5467596/pexels-photo-5467596.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    name: "Graphic Design",
    description: "Creative logo and branding design for businesses.",
    price: "$100",
    status: "اعلان",
    category: "Design",
    image: "https://images.pexels.com/photos/3851254/pexels-photo-3851254.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    name: "Digital Marketing",
    description: "SEO, social media management, and online advertising services.",
    price: "$300",
    status: "خدمة",
    category: "Marketing",
    image: "https://images.pexels.com/photos/5467596/pexels-photo-5467596.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    name: "Content Writing",
    description: "High-quality blog posts, articles, and copywriting services.",
    price: "$50",
    status: "اعلان",
    category: "Writing",
    image: "https://images.pexels.com/photos/3851254/pexels-photo-3851254.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    name: "Photography",
    description: "Professional event and portrait photography services.",
    price: "$150",
    status: "خدمة",
    category: "Photography",
    image: "https://images.pexels.com/photos/5467596/pexels-photo-5467596.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    name: "Mobile App Development",
    description: "End-to-end development of iOS and Android mobile applications.",
    price: "$2000",
    status: "اعلان",
    category: "Technology",
    image: "https://images.pexels.com/photos/3851254/pexels-photo-3851254.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    name: "Fitness Training",
    description: "Personalized fitness programs and one-on-one coaching.",
    price: "$50",
    status: "خدمة",
    category: "Health & Fitness",
    image: "https://images.pexels.com/photos/3851254/pexels-photo-3851254.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    name: "Event Planning",
    description: "Planning and coordination for weddings, parties, and corporate events.",
    price: "$1000",
    status: "اعلان",
    category: "Events",
    image: "https://images.pexels.com/photos/3851254/pexels-photo-3851254.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    name: "Legal Consultation",
    description: "Expert legal advice and contract review services.",
    price: "$200",
    status: "خدمة",
    category: "Legal",
    image: "https://images.pexels.com/photos/5467596/pexels-photo-5467596.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    name: "Plumbing Services",
    description: "Repair and installation of plumbing fixtures.",
    price: "$50",
    status: "اعلان",
    category: "Home Services",
    image: "https://images.pexels.com/photos/3851254/pexels-photo-3851254.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    name: "House Cleaning",
    description: "Comprehensive residential cleaning services.",
    price: "$100",
    status: "خدمة",
    category: "Home Services",
    image: "https://images.pexels.com/photos/5467596/pexels-photo-5467596.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    name: "Car Repair",
    description: "General maintenance and repair services for vehicles.",
    price: "$100",
    status: "اعلان",
    category: "Automotive",
    image: "https://images.pexels.com/photos/3851254/pexels-photo-3851254.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    name: "Pet Grooming",
    description: "Full-service grooming for dogs and cats.",
    price: "$30",
    status: "خدمة",
    category: "Pet Services",
    image: "https://images.pexels.com/photos/5467596/pexels-photo-5467596.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    name: "Tutoring",
    description: "Private lessons in math, science, and languages.",
    price: "$20",
    status: "اعلان",
    category: "Education",
    image: "https://images.pexels.com/photos/3851254/pexels-photo-3851254.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    name: "Interior Design",
    description: "Custom interior design and space planning services.",
    price: "$500",
    status: "خدمة",
    category: "Design",
    image: "https://images.pexels.com/photos/5467596/pexels-photo-5467596.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    name: "Lawn Care",
    description: "Mowing, landscaping, and garden maintenance services.",
    price: "$50",
    status: "اعلان",
    category: "Home Services",
    image: "https://images.pexels.com/photos/3851254/pexels-photo-3851254.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
];


function SpecialServicesCard({ name, price, status , image }) {
    return (
        <div className={RecommendedCardStyles.card}>
        <div className={RecommendedCardStyles.imagePlaceholder}>
          <img src={image} alt={name} className={RecommendedCardStyles.imagePlaceholder} />
          {/* <div className={RecommendedCardStyles.indicatorDots}>
            <span className={RecommendedCardStyles.dot}></span>
            <span className={RecommendedCardStyles.dot}></span>
            <span className={RecommendedCardStyles.dot}></span>
          </div> */}
        </div>
        
        <div className={RecommendedCardStyles.row}>
        <div className={RecommendedCardStyles.tag}>{status}</div>
        <div className={RecommendedCardStyles.tag2}>
            <HiOutlineDotsVertical className={RecommendedCardStyles.dots}/>
            <PiGlobeHemisphereWestThin className={RecommendedCardStyles.global}/>
             </div>
        </div>

        <div className={RecommendedCardStyles.productName}>{name}</div>
        
            <div className={RecommendedCardStyles.row}>
                <div className={RecommendedCardStyles.iconRow}>
                    <PiRectangleFill className={RecommendedCardStyles.location} />
                    <p className={RecommendedCardStyles.location}>
                        الجزائر - عناية
                    </p>
                </div>


                <div className={RecommendedCardStyles.price}>
                    <sup>
                        <span className={RecommendedCardStyles.dolar}>$</span>
                    </sup>

                    <span className={RecommendedCardStyles.priceNum}>{price}</span>
                </div>
            </div>

        </div>
      
    );
  }

function SpecialServicesList() {
    const sliderRef = React.useRef(null);
    const [currentIndex, setCurrentIndex] = React.useState(0);
  
    const sliderSettings = {
      dots: false,
      infinite: false,           
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 2000,
      cssEase: 'linear',
      beforeChange: (oldIndex, newIndex) => setCurrentIndex(newIndex),
    };
  
    const handlePrevious = () => {
      if (currentIndex > 0) {
        sliderRef.current.slickPrev();
      }
    };
  
    const handleNext = () => {
      if (currentIndex < services.length - 4) {
        sliderRef.current.slickNext();
      }
    };
  
    const isNextDisabled = currentIndex >= services.length - 4;
    const isPrevDisabled = currentIndex === 0;
  
    return (
      <div style={{ maxWidth: '80%', margin: '0 auto' }}>
        <Slider ref={sliderRef} {...sliderSettings}>
          {services.map((product, index) => (
            <div key={index} style={{ padding: '10px' }}>
              <SpecialServicesCard name={product.name} price={product.price} status={product.status} image={product.image} />
            </div>
          ))}
        </Slider>

      <div className={RecommendedCardStyles.btnContainer}>
        <button
      onClick={handlePrevious} 
      disabled={isPrevDisabled}
      className={isPrevDisabled ? RecommendedCardStyles.disabledButton : RecommendedCardStyles.activeButton}
        >
        <BiSolidLeftArrow />
        </button>
        <button 
         onClick={handleNext} 
         disabled={isNextDisabled}
         className={isNextDisabled ? RecommendedCardStyles.disabledButton : RecommendedCardStyles.activeButton}
        >
        <BiSolidRightArrow />
        </button>
        
      </div>
    </div>
  );
}


export default SpecialServicesList;
