import  React , {useState , useEffect , useRef} from 'react';
import { Box, Card, CardContent, Typography, Button } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import productCardStyles from './css/productCard.module.css';
import { BiSolidLeftArrow } from "react-icons/bi";
import { BiSolidRightArrow } from "react-icons/bi";
import { PiGlobeHemisphereWestThin } from "react-icons/pi";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { PiRectangleFill } from "react-icons/pi";
import ProductModal from './ProductDetails';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ProductCard({ name, price, status, images , onClick , onPress }) {
 
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    cssEase: 'linear',
  };

    return (
        <div className={productCardStyles.card}>
          
        <div className={productCardStyles.imagePlaceholder}  onClick={onClick} >
        <Slider {...sliderSettings} className={productCardStyles.innerSlider}>
        {/* {images && images.map((image, index) => (
          <div key={index} className={productCardStyles.imageContainer}> */}
            <img src={images[0]} alt={`${name} ${ + 1}`} className={productCardStyles.productImage} />
          {/* </div> */}
        {/* ))} */}
      </Slider>
        </div>
        
        <div className={productCardStyles.row}>
        <div className={productCardStyles.tag}>{status}</div>
        <div className={productCardStyles.tag2}>
            <HiOutlineDotsVertical className={productCardStyles.dots}/>
            <PiGlobeHemisphereWestThin className={productCardStyles.global}/>
             </div>
        </div>

        <div className={productCardStyles.productName}>{name}</div>
        
            <div className={productCardStyles.col}>
                <div className={productCardStyles.iconRow}>
                    <PiRectangleFill className={productCardStyles.location} />
                    <p className={productCardStyles.location}>
                        الجزائر - عناية
                    </p>
                </div>


                <div className={productCardStyles.price}>
                    <sup>
                        <span className={productCardStyles.dolar}>$</span>
                    </sup>

                    <span className={productCardStyles.priceNum}>{price}</span>
                </div>
                <div className={productCardStyles.btn}>
                  <button className={productCardStyles.button} onClick={onPress}>تواصل مع الحساب</button>
                </div>

            </div>

        </div>
      
    );
  }

function ProductList() {
    const sliderRef = useRef(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [products, setProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  useEffect(() => {
    const storedProducts = JSON.parse(localStorage.getItem('products'));
    if (Array.isArray(storedProducts)) {
      setProducts(storedProducts);
    }
  }, []);

  useEffect(() => {
    
    if (products.length > 0) {
      localStorage.setItem('products', JSON.stringify(products));
    }
  }, [products]);
    const sliderSettings = {
      dots: false,
      infinite: false,           
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 2000,
      cssEase: 'linear',
      beforeChange: (oldIndex, newIndex) => setCurrentIndex(newIndex),
    };
  
    const handlePrevious = () => {
      if (currentIndex > 0) {
        sliderRef.current.slickPrev();
      }
    };
  
    const handleNext = () => {
      if (currentIndex < products.length - 4) {
        sliderRef.current.slickNext();
      }
    };
    const openModal = (product) => {
      setSelectedProduct(product);
      setIsModalOpen(true);
    };
  
    const closeModal = () => setIsModalOpen(false);
    const isNextDisabled = currentIndex >= products.length - 4;
    const isPrevDisabled = currentIndex === 0;
    const handleButtonClick = () => {
      // Show the toast notification
      toast.warning("برجاء تحميل التطبيق", {
        position: "top-left",
        autoClose: 3000,
      //   hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: 'custom-toast', // Custom class for styling
      });
      // onClose();
    };
    return (
      <div style={{ maxWidth: '80%', margin: '0 auto' }}>
        <Slider ref={sliderRef} {...sliderSettings}>
          {products.map((product, index) => (
            <div key={index} style={{ padding: '10px' }}>
              <ProductCard name={product.name} price={product.price} status={product.status} images={product.images}  onClick={() => openModal(product)} onPress={() => handleButtonClick()} />
            </div>
          ))}
        </Slider>

      <div className={productCardStyles.btnContainer}>
        <button
      onClick={handlePrevious} 
      disabled={isPrevDisabled}
      className={isPrevDisabled ? productCardStyles.disabledButton : productCardStyles.activeButton}
        >
        <BiSolidLeftArrow />
        </button>
        <button 
         onClick={handleNext} 
         disabled={isNextDisabled}
         className={isNextDisabled ? productCardStyles.disabledButton : productCardStyles.activeButton}
        >
        <BiSolidRightArrow />
        </button>
        
      </div>
      {isModalOpen && selectedProduct && (
        <ProductModal 
          isOpen={isModalOpen} 
          onClose={closeModal} 
          name={selectedProduct.name} 
          price={selectedProduct.price} 
          images={selectedProduct.images}
        />
      )}
            <ToastContainer />

    </div>
  );
}


export default ProductList;
