import React from 'react'
import landingStyles from './css/landing.module.css';
import { LiaCartPlusSolid } from "react-icons/lia";
import ImageCarousel from './ImageCarousel';




function Landing() {
  return (
    <>
    <div className={landingStyles.container}>
    <ImageCarousel/>
    {/* <div >
    <p className={landingStyles.description}>Every Time</p>
        <h1 className={landingStyles.title}>MITTNALIER</h1>
        <button className={landingStyles.button}>
          <span className={landingStyles.addToCart}>أضف الى السلة</span>
          <LiaCartPlusSolid className={landingStyles.cartIcon} />
        </button>
    </div> */}
   
        
    </div>
    </>
  )
}

export default Landing;
