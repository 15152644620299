import React from 'react';
import { FaInstagram, FaSnapchat, FaFacebook, FaTwitter, FaTiktok, FaPhoneAlt, FaEnvelope } from 'react-icons/fa';
import footerStyles from './css/footer.module.css';
import googlePlay from './images/googleplay.png';
import googlePlayLogo from './images/svgexport-21 (15) 1.svg';
import Logo from './images/ovxlogo.png';
function Footer(){

  


    return(
        <>
           <footer className={footerStyles.footer}>
      <div className={footerStyles.footerSection}>
        <h3 className={footerStyles.header}>تطبيق <span>OVXStyle</span> </h3>
        <div className={footerStyles.appDownload}>
          <button className={footerStyles.playStore}>
          {/* <img src={googlePlayLogo} alt="App Store" /> */}
            <div className={footerStyles.googleplay}>
            {/* <p><span>OVX Style</span> APP on</p> */}
            <img src={googlePlay} alt="Google Play" />
            </div>
          
           
           

          </button>
        </div>
      </div>

      <div className={`${footerStyles.footerSection} ${footerStyles.socialIcons}`}>
        {/* <FaInstagram className="icon1" />
        <FaSnapchat className="icon1" />
        <FaFacebook className="icon1" />
        <FaTwitter className="icon1" />
        <FaTiktok className="icon1" /> */}
      </div>
      <div className={`${footerStyles.footerSection} ${footerStyles.contactInfo}`}>
        <ul>
          <li>تواصل معنا</li>
          <li>سياسة الخصوصية</li>
          <li>الأسئلة الشائعة</li>
        </ul>
        </div>
        <div  className={`${footerStyles.footerSection} ${footerStyles.contactInfoSection}`} >
          <div className='row'>
        {/* <h3>
            OVX Style</h3> */}
            <img src={Logo} alt="logo app" className={footerStyles.logo} />
          </div>
        <ul className={footerStyles.contactList}>
          <li><FaEnvelope className={footerStyles.contactIcon} /> 
          <span>Ovxstyle@Gmail.Com</span></li>
          <li><FaPhoneAlt className={footerStyles.contactIcon}  />
          <span>+973 - 39366858</span>
          </li>
          <li><FaPhoneAlt className={footerStyles.contactIcon}  />
          <span>+973 - 32172171</span> </li>
        </ul>
      </div>
      
    </footer>  
        </>
    )
}

export default Footer;
