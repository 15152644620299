import React, { useState , useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import styles from "./ProductForm.module.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router-dom';


function FormSection() {
  const navigate = useNavigate();
  const allTags = [
    "تيشرتات ",
    "أحذية مريحة",
    "قطنيات ",
    " ملابس ",
    "أحذية أنيقة وكلاسيكية",
    "أحذية سنيكرز",
    "مجموعة الصنادل كاملة",
    "أحذية رياضية",
    "صنادل ويدج",
    "أحذية بقالب واسع",
    "بوتات",
    "أحذية بكعب عالي",
  ];

  const [selectedTags, setSelectedTags] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [video, setVideo] = useState(null);
  const handleButtonClick = () => {
    // // Show the toast notification
    // toast.warning("برجاء تحميل التطبيق", {
    //   position: "top-left",
    //   autoClose: 3000,
    // //   hideProgressBar: true,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   className: 'custom-toast', // Custom class for styling
    // });
  };
  useEffect(() => {
    return () => {
      images.forEach((img) => URL.revokeObjectURL(img.preview));
      if (video) URL.revokeObjectURL(video.preview);
    };
  }, [images, video]);
  useEffect(() => {
    formik.setFieldValue("category", selectedTags);
  }, [selectedTags]);
  const validationSchema = Yup.object({
    // name: Yup.string().required("اسم العرض مطلوب"),
    // status: Yup.string().required("الحالة مطلوبة"),
    // category: Yup.array().min(1, "اختر فئة واحدة على الأقل"),
    // description: Yup.string().required("الوصف مطلوب"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      status: "",
      category: [],
      description: "",
      price:'',
    },
    validationSchema,
    onSubmit: (values) => {
      const formData = {
        ...values,
        images, // Use the `images` array directly
        video: video?.file, // Handle video if present
      };
  
      console.log("Submitting form data:", formData);
  
      // Store in localStorage
      const storedProducts = JSON.parse(localStorage.getItem("products")) || [];
      storedProducts.push(formData);
      localStorage.setItem("products", JSON.stringify(storedProducts));
  
      toast.success("تم إرسال النموذج بنجاح!");
      navigate("/");
    },
  });
  
  
  const handleImageUpload = async (e) => {
    try {
      const files = Array.from(e.target.files); // Convert FileList to Array
      const updatedImages = await Promise.all(
        files.map((file) => convertToBase64(file)) // Convert each file to Base64
      );
  
      setImages([...images, ...updatedImages]); // Update state with Base64 strings
    } catch (err) {
      console.error("Error in handleImageUpload:", err);
      alert("حدث خطأ أثناء تحميل الصور.");
    }
  };
  
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file); // Read file as Base64
      reader.onload = () => resolve(reader.result); // Resolve with Base64 string
      reader.onerror = (error) => reject(error);
    });
  };  
  
  const handleVideoUpload = (e) => {
    const file = e.target.files[0];
    
    if (file && file instanceof File) { // Ensure it's a valid file
      setVideo({ file, preview: URL.createObjectURL(file) });
    } else {
      alert("الملف غير صالح.");
    }
  };

   const addTag = (tag) => {
    if (!selectedTags.includes(tag)) {
      setSelectedTags([...selectedTags, tag]);
      formik.setFieldValue("category", [...selectedTags, tag]);
    }
    setIsDropdownOpen(false);
  };

   const removeTag = (tagToRemove) => {
    const updatedTags = selectedTags.filter((tag) => tag !== tagToRemove);
    setSelectedTags(updatedTags);
    formik.setFieldValue("category", updatedTags);
  };
  const handleDivClick = () => {
    document.getElementById("imgs").click(); // Trigger file input click
  };
  const handleVideoClick = () => {
    document.getElementById("video").click(); // Trigger file input click
  };
  return (
    <section className={styles.formSection}>
      <form className={styles.formContent} onSubmit={formik.handleSubmit}>
        <header className={styles.formHeader}> </header>

        <label htmlFor="images" className={styles.formLabel}>
          <span className={styles.requiredField}>*</span> صور
        </label>
        <div className={styles.imageUpload}>
          <div
            onClick={handleDivClick}
            className={styles.uploadContent}
            role="button"
            tabIndex={0} // Makes the div focusable
          >
            <img
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/7884097dbe4a1fcc2be7a1a548890ead6b456523ae4c1250d9c414bedec877f9?placeholderIfAbsent=true&apiKey=52ee78b612ab4e92b3964be63b294cdb"
              alt="Upload Icon"
              className={styles.uploadIcon}
            />
            <p className={styles.uploadInstructions}>
              <span style={{ color: "#303030" }}>اختر يدويًا</span> أو اسحب
              الملفات هنا
            </p>
            <p className={styles.uploadLimit}>اضم صور العرض بحد اقصي 5 صور </p>
          </div>

          {/* Hidden file input */}
          <input
            id="imgs"
            type="file"
            multiple
            onChange={handleImageUpload}
            accept="image/*"
            style={{ display: "none" }} // Hide the file input
          />
        </div>
        {images.length > 0 && (
          <div className={styles.imageUpload}>
            {/* {images.map((img, index) => (
              <img
                key={index}
                width={150}
                height={150}
                // src={URL.createObjectURL(img)}
                src={img.preview}
                alt="Preview"
                className={styles.previewImage}
              />
            ))} */}
            تم رفع الصور بنجاح !
          </div>
        )}
        <label htmlFor="serviceName" className={styles.formLabel}>
          <span className={styles.requiredField}>*</span> اسم العرض
        </label>
        <input
          id="serviceName"
          name="name"
          type="text"
          className={styles.formInput}
          placeholder="اسم المنتج"
          onChange={formik.handleChange}
          value={formik.values.name}
        />
        {formik.errors.name && formik.touched.name && (
          <div className={styles.error}>{formik.errors.name}</div>
        )}

<label htmlFor="serviceName" className={styles.formLabel}>
          <span className={styles.requiredField}>*</span>السعر 
        </label>
        <input
          id="serviceName"
          name="price"
          type="text"
          className={styles.formInput}
          placeholder="السعر"
          onChange={formik.handleChange}
          value={formik.values.price}
        />
        {formik.errors.name && formik.touched.name && (
          <div className={styles.error}>{formik.errors.name}</div>
        )}

        <label htmlFor="status" className={styles.formLabel}>
          <span className={styles.requiredField}>*</span> الحالة
        </label>
        <select
          id="status"
          name="status"
          className={styles.formSelect}
          onChange={formik.handleChange}
          value={formik.values.status}
        >
          <option value="">اختر الحالة</option>
          <option value="جديد">جديد</option>
          <option value="مستعمل">مستعمل</option>
        </select>
        {formik.errors.status && formik.touched.status && (
          <div className={styles.error}>{formik.errors.status}</div>
        )}

        <label htmlFor="category" className={styles.formLabel}>
          <span className={styles.requiredField}>*</span> الفئات
        </label>
        <div style={styles.dropdown}>
          <button
            type="button"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className={styles.formSelect}
          >
            {isDropdownOpen ? "إغلاق" : "أختر الفئات"}
          </button>
          {isDropdownOpen && (
            <div style={styles.dropdownContent} className="row">
              {allTags.map((tag, index) => (
                <div
                  className="col-4 item text-end py-1 border-1 rounded bold"
                  key={index}
                  onClick={() => addTag(tag)}
                >
                  <button className="btn btn-outline-primary w-100">
                    {tag}
                  </button>
                </div>
              ))}
            </div>
          )}
          <div className="tagsContainer bg-light row justify-content-around mt-2">
            {selectedTags.map((tag, index) => (
              <div key={index} className="col-4 text-end">
                {tag}
                <span className="p-2" onClick={() => removeTag(tag)}>
                  <i className="fa-solid fa-trash" style={{ color: "red" }}></i>
                </span>
              </div>
            ))}
          </div>
        </div>
        {formik.errors.category && formik.touched.category && (
          <div className={styles.error}>{formik.errors.category}</div>
        )}

        <label htmlFor="description" className={styles.formLabel}>
          <span className={styles.requiredField}>*</span> الوصف
        </label>
        <textarea
          id="description"
          name="description"
          className={styles.formTextarea}
          placeholder="وصف قصير"
          onChange={formik.handleChange}
          value={formik.values.description}
        />
        {formik.errors.description && formik.touched.description && (
          <div className={styles.error}>{formik.errors.description}</div>
        )}

        <label htmlFor="video" className={styles.formLabel}>
          <span className={styles.requiredField}>*</span> فيديو
        </label>

        <input
          style={{ display: "none" }} // Hide the file input
          id="video"
          type="file"
          onChange={handleVideoUpload}
          accept="video/*"
        />
        <div className={styles.imageUpload}>
          <div
            onClick={handleVideoClick}
            className={styles.uploadContent}
            role="button"
            tabIndex={0}  
          >
            <img
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/7884097dbe4a1fcc2be7a1a548890ead6b456523ae4c1250d9c414bedec877f9?placeholderIfAbsent=true&apiKey=52ee78b612ab4e92b3964be63b294cdb"
              alt="Upload Icon"
              className={styles.uploadIcon}
            />
            <p className={styles.uploadInstructions}>
              <span style={{ color: "#303030" }}>اختر يدويًا</span> أو اسحب
              الملفات هنا
            </p>
            <p className={styles.uploadLimit}>ارفع فديو هنا</p>
            {video && <p>{video.name}</p>}
          </div>
        </div>

        <div>
      {/* Yellow "إضافة" buttons with labels */}
      <div  >
      <div className={styles.buttonSection}>
        <button   onClick={handleButtonClick} >إضافة +</button>
        <span className={styles.label}>الألوان والأحجام و سعر  </span>
        </div>
        <div className={styles.buttonSection}>

        <button   onClick={handleButtonClick} >إضافة +</button>
        <span className={styles.label}>تكاليف الشحن والخصومات والضرائب  </span>
      </div>

        <div className={styles.buttonSection}>

        <button   onClick={handleButtonClick} >إضافة +</button>
        <span className={styles.label}>كوبون خصم</span>
      </div>

        <div className={styles.buttonSection}>

        <button   onClick={handleButtonClick} >إضافة +</button>
        <span className={styles.label}>ميزات إضافية</span>
      </div>

        <div className={styles.buttonSection}>

        <button   onClick={handleButtonClick} >إضافة +</button>
        <span className={styles.label}>اختر موقعا للمنتج </span>
      </div>
      </div>

      {/* Black "أضف مع الأجر" button */}
      <div className={styles.premiumProduct}>
        <span className={styles.premiumText}>منتج مميز</span>
        <button  onClick={handleButtonClick} className={styles.premiumButton}>أضف مع الأجر $ 9.99 +</button>
      </div>

      {/* Payment method selection */}
      <div className={styles.paymentMethod}>
        <span className={styles.label}>اختر طريقة الدفع *</span>
        <div className={styles.paymentButtons}>
          <button   onClick={handleButtonClick} className={styles.paymentButton}>تواصل مع البائع</button>
          <button   onClick={handleButtonClick} className={styles.paymentButton}>OVX Pay</button>
        </div>
      </div>
    </div>
        <button
          type="submit"
          className="btn btn-lg w-100 mt-5"
          style={{
            backgroundColor: "#FFDE59",
            color: "fff",
            fontWeight: "400",
          }}
          // onClick={handleButtonClick}
        >
          إرسال
        </button>
      </form>
      <ToastContainer />
    </section>
  );
}

export default FormSection;
