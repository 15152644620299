import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination } from 'swiper/modules';
import verifiedAccountStyles from './css/verifiedAccounts.module.css';
import verifyUser from './images/verify.png';
function VerifiedAccounts() {
    const accounts = [
        { id: 1, name: 'صبا عطالله', country: 'فلسطين', products: 50 },
        { id: 2, name: 'محمود الصاوى', country: 'فلسطين', products: 50 },
        { id: 3, name: 'عطالله', country: 'فلسطين', products: 50 },
        { id: 4, name: 'احمد محمد', country: 'فلسطين', products: 50 },
    ];

    return (
        <>
          <style>
        {`
            .swiper-pagination-bullet {
                background-color: #f2d28b !important; /* Default color for bullets */
                width: 12px !important;
                height: 12px !important;
                opacity: 1 !important;
                margin: 0 5px !important;
            }
            .swiper-pagination-bullet-active {
                background-color: #ffc107 !important; /* Color for active bullet */
            }
        `}
    </style>
    <div className={verifiedAccountStyles.headerContainer}>
            <h2 className={verifiedAccountStyles.title}>الحسابات الموثقة</h2>
            <p className={verifiedAccountStyles.seeMore}>عرض الكل</p>
        </div>
        <Swiper
            modules={[Pagination]}
            spaceBetween={20}
            slidesPerView={3}
            pagination={{ clickable: true }}
            loop={false}
            className={verifiedAccountStyles.swiperContainer}
        >
            {accounts.map((account) => (
                <SwiperSlide key={account.id} className={verifiedAccountStyles.card}>
                    <div className={verifiedAccountStyles.accountCard}>
                        <span className={verifiedAccountStyles.country}>🇵🇸 {account.country}</span>
                        <div className={verifiedAccountStyles.userInfo}>
                        <h2 className={verifiedAccountStyles.name}>{account.name}</h2>
                        <p className={verifiedAccountStyles.products}>{account.products} منتج</p>
                        </div>
                        <div className={verifiedAccountStyles.avatar}>
                            <img className={verifiedAccountStyles.verifiedIcon} src={verifyUser}/>
                        </div>
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
        </>
      
    );
}

export default VerifiedAccounts;
