import React, { useState } from 'react';
import SpecialOffersStyles from './css/SpecialOffers.module.css'
import SpecialServicesList from './SpecialServicesCard';


function SpecialServices() {
 

  return (
    <div className={SpecialOffersStyles.container}>
        <div className={SpecialOffersStyles.headerContainer}>
            <h2 className={SpecialOffersStyles.title}>الخدمات المميزة</h2>
            <p className={SpecialOffersStyles.seeMore}>عرض الكل</p>
        </div>
      <SpecialServicesList/>
    </div>
  );
}



export default SpecialServices;
