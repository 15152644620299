import React from "react";
import MainComponent from "../components/forms/MainComponent";
import styles from "../components/forms/ServiceForm.module.css";
import ServiceForm from "../components/forms/ServiceForm";

const AddService = () => {
  return (
    <>
      <MainComponent pageTitle="إضافة خدمة" /> 
      <div className={styles.container}>
        <div className={styles.formLayout}>
          <ServiceForm />
        </div>
      </div>
    </>
  );
};

export default AddService;
