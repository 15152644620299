import React from 'react'
import CompetitionStyles from './css/competitions.module.css';
import SpecialJobsList from './SpecialJobsCard';
function SpecialJobs() {
  return (
    <div className={CompetitionStyles.container}>
        <div className={CompetitionStyles.headerContainer}>
            <h2 className={CompetitionStyles.title}>الوظائف المميزة</h2>
            <p className={CompetitionStyles.seeMore}>عرض الكل</p>
        </div>
        <SpecialJobsList/>
    </div>
  )
}

export default SpecialJobs;
