import React from 'react'
import RecommendedStyles from './css/recommended.module.css';
import ProductList from './ProductCard';
function Recommended() {
  return (
    <div className={RecommendedStyles.container}>
        <div className={RecommendedStyles.headerContainer}>
            <h2 className={RecommendedStyles.title}>منتجات مميزة</h2>
            <p className={RecommendedStyles.seeMore}>عرض الكل</p>
        </div>
        <ProductList/>
    </div>
  )
}

export default Recommended
