import React from 'react'
import searchStyles from './css/searchBar.module.css';
import { FaBars } from "react-icons/fa";
import { CiSearch } from "react-icons/ci";
import Logo from './images/logo.svg';
function SearchBar() {
  return (
    <>
   <div className={searchStyles.container} >
    <div>
    <FaBars className={searchStyles.bars} />
    <div className={searchStyles.title}> 
      <img src={Logo} alt="logo app"
       />
       <h1>OVX Style</h1>
       </div>
    </div>
    <div className={searchStyles.searchContainer}>
    <input className={searchStyles.input} type="text" placeholder="ابحث عن ماذا تريد؟" />
     <button className={searchStyles.button}>
       <CiSearch className={searchStyles.searchIcon} />
     </button>
    </div>
   
   </div>
    </>
  )
}

export default SearchBar