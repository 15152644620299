import React from 'react';
import styles from './css/UserProfile.module.css';
import StatItem from './StatItem';
import MainComponent from './forms/MainComponent';

const UserProfile = () => {
  const stats = [
    { value: 51, label: 'المتابعون' },
    { value: 51, label: 'يتابع' },
    { value: 51, label: 'الإعجابات' },
    { value: 51, label: 'العروض' }
  ];

  return (
    // <MainComponent pageTitle=" " /> 

    <article className={styles.profileCard}>
      <div className={styles.profileInfo}>
        <header className={styles.profileHeader}>
          <div className={styles.actionButtons}>
            <button className={styles.followButton}>متابعة</button>
            <button className={styles.chatButton}>ابدأ الدردشة</button>
          </div>
          <h1 className={styles.userName}>Seba atallah</h1>
        </header>
        <div className={styles.userDetails}>
          <div className={styles.userHandle}>
            <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/e8d1448f966e262d93aa577b1d2d0cc3595d6a49baf2547fa0465f7ab0090511?placeholderIfAbsent=true&apiKey=52ee78b612ab4e92b3964be63b294cdb" alt="" className={styles.userIcon} />
            <span>user45892</span>
          </div>
          <div className={styles.statsContainer}>
            {stats.map((stat, index) => (
              <StatItem key={index} value={stat.value} label={stat.label} />
            ))}
          </div>
        </div>
      </div>
      <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/9193c311-56ba-4255-b55d-e6eb45574291?placeholderIfAbsent=true&apiKey=52ee78b612ab4e92b3964be63b294cdb" alt="Profile" className={styles.profileImage} />
    </article>
  );
};

export default UserProfile;