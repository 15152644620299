import React, { useState, useEffect } from "react";
import navbarStyles from "./css/navbar.module.css";
import { FaShoppingCart, FaBell, FaGlobe } from "react-icons/fa";
import { IoIosGlobe } from "react-icons/io";
import flag from "./images/flag.png";
import userImage from "./images/user.png";
import Badge from "./Badge";
import { useNavigate } from "react-router-dom";

function Navbar() {
  const [cartCount, setCartCount] = useState(0);
  const [notificationCount, setNotificationCount] = useState(0);
  const [selectedCountry, setSelectedCountry] = useState("البحرين");
  const [selectedCurrency, setSelectedCurrency] = useState("USD");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [firstName, setFirstName] = useState("");
  const navigate = useNavigate();

  // Simulate fetching user login status and first name from localStorage
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("isLoggedIn"));
    if (userData ) {
      setIsLoggedIn(true);
      // setFirstName(userData.firstName);
    }
  }, []);

  // List of countries and currencies
  const countries = ["البحرين", "مصر", "السعودية", "الإمارات", "الأردن"];
  const currencies = ["USD", "AED", "SAR", "EGP", "BHD"];

  return (
    <>
      <div className={navbarStyles.navbar}>
        <div className={navbarStyles.navbarLeft}>
          {/* Cart and Notifications */}
          <div className={`${navbarStyles.iconText} ${navbarStyles.leftIcons}`}>
             {isLoggedIn ? (
              <img className={navbarStyles.icon} src={userImage} alt="User" />
          ) : (
             
            <div    onClick={() => navigate("/login")} className={navbarStyles.icon}>
              تسجيل الدخول    
            </div>
          )}
              <Badge count={cartCount}>
                <FaShoppingCart className={navbarStyles.icon} />
              </Badge>
              <Badge count={notificationCount}>
                <FaBell className={navbarStyles.icon} />
              </Badge>
           </div>

          {/* Country Dropdown */}
          <div className={navbarStyles.iconText}>
            <FaGlobe className={navbarStyles.icon} />
            <select
              className={navbarStyles.dropdown}
              value={selectedCountry}
              onChange={(e) => setSelectedCountry(e.target.value)}
            >
              {countries.map((country, index) => (
                <option key={index} value={country}>
                  {country}
                </option>
              ))}
            </select>
          </div>

          {/* Currency Dropdown */}
          <div className={navbarStyles.iconText}>
            <IoIosGlobe className={navbarStyles.icon} />
            <select
              className={navbarStyles.dropdown}
              value={selectedCurrency}
              onChange={(e) => setSelectedCurrency(e.target.value)}
            >
              {currencies.map((currency, index) => (
                <option key={index} value={currency}>
                  {currency}
                </option>
              ))}
            </select>
          </div>

          {/* Country Flag */}
          {/* <div className={navbarStyles.iconText}>
            <img className={navbarStyles.icon} src={flag} alt="Flag" />
            <span>{selectedCountry}</span>
          </div> */}
        </div>

        {/* Right Section */}
        <div
          onClick={() => navigate("/")}
          className={navbarStyles.navbarRight}
        >
          {isLoggedIn ? (
            <p>
              أهلا، <span>{firstName}</span> في <span>OVX Style</span>
            </p>
          ) : (
            <p>
             سجل <span>{firstName}</span> معنا في <span>OVX Style</span>
          </p>
          )}
        </div>
      </div>
    </>
  );
}

export default Navbar;
