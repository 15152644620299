import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
  RecaptchaVerifier, signInWithPhoneNumber
} from "firebase/auth";
import { getFirestore, collection, getDocs } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyA5f3WTJLcGcUSLIiJO9PZO-gmfHxvhEcU",
  authDomain: "ovx-style-de43a.firebaseapp.com",
  projectId: "ovx-style-de43a",
  storageBucket: "ovx-style-de43a.appspot.com",
  messagingSenderId: "191263524287",
  appId: "1:191263524287:web:eea8d77c8d6d481386cd0a",
  measurementId: "G-3ZVZ8N70ZF",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
auth.settings.appVerificationDisabledForTesting = false;

export { auth, googleProvider, facebookProvider, db, collection, getDocs, RecaptchaVerifier, signInWithPhoneNumber };
