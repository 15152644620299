import React , {useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

// Import Pages
import Home from './pages/Home';
import About from './pages/About';
import AddProduct from './pages/Addproduct';
import AddService from './pages/AddService';
import AddAuction from './pages/AddAuction';
import AddJob from './pages/AddJob';
import Login from './pages/auth/Login';
import SignUp from "./pages/auth/SignUp";
import Profile from "./components/UserProfile";
import product from "./products.json";
function App() {
  useEffect(() => {
    // Check if products are already in localStorage
    const storedProducts = localStorage.getItem("products");
    if (!storedProducts) {
      localStorage.setItem("products", JSON.stringify(product.products));
      console.log("Products saved to localStorage:", product.products);
    }
  }, []);
  return (
    <Router>
      <div className="App">
        <Navbar />  {/* This will always be visible */}
         {/* Define the routes */}
        <Routes>
          <Route path="/" element={<Home />} />  {/* Home page */}
          <Route path="/about" element={<About />} />  {/* About page */}
          <Route path="/add-service" element={<AddService />} />   
          <Route path="/add-product" element={<AddProduct />} />   
          <Route path="/add-auction" element={<AddAuction />} />   
          <Route path="/add-job" element={<AddJob/>} />   
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/profile" element={<Profile />} />
         </Routes>

        <Footer />  {/* This will always be visible */}
      </div>
    </Router>
  );
}

export default App;
