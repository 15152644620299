import React, { useState } from 'react';
import SpecialOffersStyles from './css/SpecialOffers.module.css'
import SpecialOffersList from './SpecialOffersCard';


function SpecialOffers() {
 

  return (
    <div className={SpecialOffersStyles.container}>
        <div className={SpecialOffersStyles.headerContainer}>
            <h2 className={SpecialOffersStyles.title}>العروض اليومية</h2>
            <p className={SpecialOffersStyles.seeMore}>عرض الكل</p>
        </div>
      <SpecialOffersList/>
    </div>
  );
}



export default SpecialOffers;
