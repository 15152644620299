import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { auth, RecaptchaVerifier, signInWithPhoneNumber } from "../../firebase/firebase";
import {
  createUserWithEmailAndPassword,
  updateProfile,
  sendEmailVerification,
} from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./signUp.module.css";
import { useNavigate } from "react-router-dom";

const SignUp = () => {
 const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationId, setVerificationId] = useState("");
  const [otp, setOtp] = useState("");
  const [showOtpPopup, setShowOtpPopup] = useState(false); // Controls OTP popup visibility
  const navigate = useNavigate();
  const GoTOSignUP = ()=>{
    navigate('/login');
}
  // Validation Schema
  const signUpValidationSchema = Yup.object().shape({
    fullName: Yup.string().required("الاسم بالكامل مطلوب"),
    lastName: Yup.string().required("اللقب مطلوب"),
    email: Yup.string().email("البريد الإلكتروني غير صحيح").required("البريد الإلكتروني مطلوب"),
    phone: Yup.string().required("رقم الهاتف مطلوب"),
    birthdate: Yup.date().required("تاريخ الميلاد مطلوب"),
    gender: Yup.string().required("الجنس مطلوب"),
    address: Yup.string().required("العنوان مطلوب"),
    password: Yup.string()
      .min(8, "كلمة المرور يجب أن تكون على الأقل 8 أحرف")
      .required("كلمة المرور مطلوبة"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "كلمات المرور غير متطابقة")
      .required("تأكيد كلمة المرور مطلوب"),
  });

  const GoToSignIn = () => {
    navigate("/login");
  };

  // const setupRecaptcha = () => {
  //   if (!window.recaptchaVerifier) {
  //     window.recaptchaVerifier = new RecaptchaVerifier(
  //       "recaptcha-container",
  //       {
  //         size: "invisible",
  //         callback: () => {
  //           console.log("Recaptcha verified successfully.");
  //         },
  //         "expired-callback": () => {
  //           console.log("Recaptcha expired. Please try again.");
  //         },
  //       },
  //       auth
  //     );
  //   }
  // };

  // // Send OTP to Phone
  // const sendOtp = async (phone) => {
  //   setupRecaptcha();
  //   const appVerifier = window.recaptchaVerifier;

  //   try {
  //     const confirmationResult = await signInWithPhoneNumber(auth, phone, appVerifier);
  //     setVerificationId(confirmationResult.verificationId);
  //     setShowOtpPopup(true); // Show the OTP popup
  //     toast.success("تم إرسال رمز التحقق إلى هاتفك!");
  //   } catch (error) {
  //     console.error("Error sending OTP:", error.message);
  //     toast.error("حدث خطأ أثناء إرسال رمز التحقق.");
  //   }
  // };

  // // Verify OTP
  // const verifyOtp = async () => {
  //   if (!otp) {
  //     toast.error("يرجى إدخال رمز التحقق.");
  //     return;
  //   }
  //   try {
  //     const credential = await window.confirmationResult.confirm(otp);
  //     toast.success("تم التحقق من رقم الهاتف بنجاح!");
  //     setShowOtpPopup(false); // Close the popup on success
  //   } catch (error) {
  //     console.error("Error verifying OTP:", error.message);
  //     toast.error("رمز التحقق غير صحيح.");
  //   }
  // };

  // Handle Email/Password Sign-Up
  const handleEmailSignUp = async (values) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, values.email, values.password);
      const user = userCredential.user;

      // Update the user's profile with additional info
      await updateProfile(user, {
        displayName: `${values.fullName} ${values.lastName}`,
      });

      // Send email verification
      await sendEmailVerification(user);
      toast.success("تم إنشاء الحساب بنجاح! يرجى التحقق من بريدك الإلكتروني لتفعيل الحساب.");

      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } catch (error) {
      console.error("Error during sign-up:", error.message);
      toast.error(error.message || "حدث خطأ أثناء إنشاء الحساب.");
    }
  };


  return (
    <>
      <main className={styles.signUpContainer}>
        <ToastContainer />
        <h1 className={styles.signUpTitle}>إنشاء حساب</h1>

        <Formik
          initialValues={{
            fullName: "",
            lastName: "",
            email: "",
            phone: "",
            birthdate: "",
            gender: "",
            address: "",
            password: "",
            confirmPassword: "",
          }}
          validationSchema={signUpValidationSchema}
          onSubmit={(values, { setSubmitting }) => {
            setPhoneNumber(values.phone);
            // sendOtp(values.phone);
            handleEmailSignUp(values); // Handle Email/Password Sign-Up
            setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
         <Form>
         <div className={styles.inputContainer}>
           <label className={styles.inputLabel}>الاسم بالكامل</label>
           <Field
             type="text"
             name="fullName"
             className={styles.inputField}
             placeholder="الاسم بالكامل"
           />
           <ErrorMessage name="fullName" component="div" className={styles.error} />
         </div>

         <div className={styles.inputContainer}>
           <label className={styles.inputLabel}>اللقب</label>
           <Field
             type="text"
             name="lastName"
             className={styles.inputField}
             placeholder="اللقب"
           />
           <ErrorMessage name="lastName" component="div" className={styles.error} />
         </div>

         <div className={styles.inputContainer}>
           <label className={styles.inputLabel}>البريد الإلكتروني</label>
           <Field
             type="email"
             name="email"
             className={styles.inputField}
             placeholder="البريد الإلكتروني"
           />
           <ErrorMessage name="email" component="div" className={styles.error} />
         </div>

         <div className={styles.inputContainer}>
           <label className={styles.inputLabel}>رقم الهاتف</label>
           <Field
             type="tel"
             name="phone"
             className={styles.inputField}
             placeholder="رقم الهاتف"
           />
           <ErrorMessage name="phone" component="div" className={styles.error} />
         </div>

         <div className={styles.inputContainer}>
           <label className={styles.inputLabel}>تاريخ الميلاد</label>
           <Field type="date" name="birthdate" className={styles.inputField} />
           <ErrorMessage name="birthdate" component="div" className={styles.error} />
         </div>

         <div className={styles.inputContainer}>
           <label className={styles.inputLabel}>الجنس</label>
           <Field as="select" name="gender" className={styles.selectField}>
             <option value="">اختر</option>
             <option value="male">ذكر</option>
             <option value="female">أنثى</option>
           </Field>
           <ErrorMessage name="gender" component="div" className={styles.error} />
         </div>

         <div className={styles.inputContainer}>
           <label className={styles.inputLabel}>العنوان</label>
           <Field
             type="text"
             name="address"
             className={styles.inputField}
             placeholder="العنوان"
           />
           <ErrorMessage name="address" component="div" className={styles.error} />
         </div>

         <div className={styles.inputContainer}>
           <label className={styles.inputLabel}>كلمة المرور</label>
           <Field
             type="password"
             name="password"
             className={styles.inputField}
             placeholder="********"
           />
           <ErrorMessage name="password" component="div" className={styles.error} />
         </div>

         <div className={styles.inputContainer}>
           <label className={styles.inputLabel}>تأكيد كلمة المرور</label>
           <Field
             type="password"
             name="confirmPassword"
             className={styles.inputField}
             placeholder="********"
           />
           <ErrorMessage
             name="confirmPassword"
             component="div"
             className={styles.error}
           />
         </div>
         <div id="recaptcha-container"></div>

         <button
           type="submit"
           className={styles.signUpButton}
           disabled={isSubmitting}
         >
           إنشاء حساب
         </button>
         <p className={styles.signupPrompt} >
       تملك حساب ؟ <a onClick={GoTOSignUP}  className={styles.signupLink}>تسجيل الدخول   </a>
   </p>
       </Form>
          )}
        </Formik>
      </main>

      {/* OTP Popup */}
      {/* {showOtpPopup && (
        <div className={styles.otpPopup}>
          <div className={styles.otpPopupContent}>
            <h2>تأكيد رمز التحقق</h2>
            <p>أدخل رمز التحقق الذي تم إرساله إلى {phoneNumber}</p>
            <input
              type="text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              className={styles.inputField}
              placeholder="رمز التحقق"
            />
            <button className={styles.signUpButton} onClick={verifyOtp}>
              تأكيد
            </button>
            <button
              className={styles.closeButton}
              onClick={() => setShowOtpPopup(false)}
            >
              إغلاق
            </button>
          </div>
        </div>
      )} */}
    </>
  );
};

export default SignUp;
